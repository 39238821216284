<script>
export default {
    name: "CategorySquare",
    props:{
        category: {
            type: Object
        },
        selected_category: {
            type: String,
            default: ""
        }
    },
    data(){
        return{
            // language mapper
            en_category: {
              // skincare
              "基礎化粧品": "basic cosmetics",
              "クレンジング": "cleansing",
              "洗顔": "face wash",
              "スペシャルケア": "special care",
              "化粧水": "skin lotion",
              "乳液・クリーム": "milky lotion, cream",
              // makeup
              "下地": "base makeup",
              "ファンデーション": "foundation",
              "ハイライト": "highlighter",
              "シェーディング": "shading",
              "コンシーラー": "concealer",
              "フェイスパウダー": "face powder",
              "チーク": "blush",
              "アイシャドウ": "eyeshadow",
              "アイライナー": "eyeliner",
              "マスカラ": "mascara",
              "アイブロウ": "eyebrow",
              "（ペンシル）": "pencil",
              "（パウダー）": "powder",
            },
            zh_category: {
              // skincare
              "基礎化粧品": "基础化妆品",
              "クレンジング": "卸妆液",
              "洗顔": "洁⾯",
              "スペシャルケア": "特殊照护",
              "化粧水": "化妆⽔",
              "乳液・クリーム": "乳液・乳霜",
              // makeup
              "下地": "底妆",
              "ファンデーション": "粉底",
              "ハイライト": "高光",
              "シェーディング": "阴影",
              "コンシーラー": "遮瑕膏",
              "フェイスパウダー": "扑面粉",
              "チーク": "腮红",
              "アイシャドウ": "眼影",
              "アイライナー": "眼线",
              "マスカラ": "睫毛膏",
              "アイブロウ": "眉笔",
              "（ペンシル）": "- 笔 -",
              "（パウダー）": "- 粉 -",
            },
        }
    },
    computed:{
        isSelected(){
            return this.selected_category == this.category.name
        },
        filteredCategoryMainName(){
            const bracket_position = this.category.name.indexOf("（")
            if(bracket_position==-1){
                // add language mapper
                if(this.$i18n.locale == "en"){
                    return this.en_category[this.category.name]
                }else if(this.$i18n.locale == "zh"){
                    return this.zh_category[this.category.name]
                }else{
                    return this.category.name
                }
            }else{
                // add language mapper
                if(this.$i18n.locale == "en"){
                    return this.en_category[this.category.name.substring(0,bracket_position)]
                }else if(this.$i18n.locale == "zh"){
                    return this.zh_category[this.category.name.substring(0,bracket_position)]
                }else{
                    return this.category.name.substring(0,bracket_position)
                }
            }
        },
        filteredCategorySubName(){
            const bracket_position = this.category.name.indexOf("（")
            if(bracket_position==-1){
                return false
            }else{
                // add language mapper
                if(this.$i18n.locale == "en"){
                    return this.en_category[this.category.name.substring(bracket_position)]
                }else if(this.$i18n.locale == "zh"){
                    return this.zh_category[this.category.name.substring(bracket_position)]
                }else{
                    return this.category.name.substring(bracket_position)
                }
            }
        },
        isBlank(){
          return this.category.name == "blank"
        }
    },
}
</script>

<template>
  <div
    :class="
      {
        item_categories_btn: !isBlank,
        selected: isSelected && !isBlank,
        isblank: isBlank
      }
    "
  >
    <div
      v-if="!isBlank"
      :class="{selected_child: isSelected}"
      class="item_categories_btn_inner"
    >
      <img
        :src="
          require(
            '@/assets/category_icons/'
              + category.code
              + '.svg'
          )
        "
      >
      <p>
        {{ filteredCategoryMainName }}
      </p>
      <p v-if="filteredCategorySubName">
        {{ filteredCategorySubName }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.item_categories_btn {
  background-color: #fff;
  width: 33%;
  padding-top: 33%;
  position: relative;
  margin-top:5px;
  margin-bottom: 5px;
}
.item_categories_btn_inner {
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
  box-sizing: content-box;
}
.item_categories_btn_inner img{
    width: 50%;
    padding-bottom: 10px;
}
.item_categories_btn_inner p{
    font-size: 14px;
}
.selected {
  margin-bottom: -15px;
  background-color: #fff;
  box-sizing: content-box;
}
.selected_child{
    top: -15px;
}
.isblank {
  background-color: #F5EAE7;
  width: 33%;
  padding-top: 33%;
  position: relative;
  margin-top:5px;
  margin-bottom: 5px;

}
</style>
